import React from 'react';
import HeroBadge from './HeroBadge';
import {
  IconContainer,
  ReviewsContainer,
  SocialReviews,
  Wrapper,
} from '../styles/HeroSocialProof.styles';
import { socialProofBadges } from '../utils/mappings';
import { colors } from '../../../styles/atoms/colors';
import Icon from '../../../styles/atoms/icons';
import { getSpanishText } from '../../../utils/temporalSpanishManagement';

const SocialProof = ({ isCentered, colorMap, slug, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <ReviewsContainer isCentered={isCentered}>
        {[...Array(5).keys()].map(i => (
          <span key={i}>
            <Icon id="gold-star-icon" isImage />
            {/* <Image src={star} alt="star" /> */}
          </span>
        ))}
        <SocialReviews color={colorMap?.copy}>
          {getSpanishText(slug).HERO_SOCIAL_PROOF}
        </SocialReviews>
      </ReviewsContainer>
      <IconContainer isCentered={isCentered}>
        {socialProofBadges.map(badge => (
          <HeroBadge
            key={badge.name}
            isWhite={colorMap?.heading === colors.base.white}
            src={badge.src}
            name={badge.name}
            href={badge.href}
            viewBox={badge.viewBox}
          >
            {badge.icon}
          </HeroBadge>
        ))}
      </IconContainer>
    </Wrapper>
  );
};

export default SocialProof;
