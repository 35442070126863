export function getSpanishText(slug) {
  if (slug && slug.includes('spanish')) {
    return es;
  }
  return en;
}
const es = {
  HERO_SOCIAL_PROOF: 'Basado en 600+ reseñas en',
  PRICING: {
    MONTHLY_PLANS: 'Planes mensuales',
    ANNUAL_SAVINGS: 'Ahorros anuales',
    BILLED_MONTHLY: 'Facturado mensualmente',
    BILLED_ANNUALLY: 'Facturado anualmente',
    MONTH: 'Mes',
    TALK_TO_US: 'Habla con nosotros',
    CONTACT_US: 'Contáctanos',
    REQUEST_DEMO: 'Solicita una demostración',
    MOST_POPULAR: 'Más popular',
    SEE_ALL_FEATURES: 'Ver todas las características',
  },
  ADD_ONS: {
    MONTH: 'Mes',
    REQUEST_DEMO: 'Solicita una demostración',
  },
};

const en = {
  HERO_SOCIAL_PROOF: 'Based on 600+ reviews on',
  PRICING: {
    MONTHLY_PLANS: 'Monthly Plans',
    ANNUAL_SAVINGS: 'Annual Savings',
    BILLED_MONTHLY: 'Billed Monthly',
    BILLED_ANNUALLY: 'Billed Annually',
    MONTH: 'Mo',
    TALK_TO_US: 'Talk to Us',
    CONTACT_US: 'Contact Us',
    REQUEST_DEMO: 'Request a Demo',
    MOST_POPULAR: 'Most Popular',
    SEE_ALL_FEATURES: 'See All Features',
  },
  ADD_ONS: {
    MONTH: 'Mo',
    REQUEST_DEMO: 'Request a Demo',
  },
};
